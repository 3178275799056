<template>
  <div class="login_container">
    <!-- <div class="shouji">
      <img class="shoujia" src="../assets/shouji.png" alt />
    </div>-->

    <div class="wrapper">
      <div class="logo">
        <img class="shoujia" src="../assets/logo.png" alt />
        <div class="text">商用车大数据平台</div>
      </div>
      <!-- s手机号加密码登录 -->
      <div class="login_box" v-if="statu">
        <!-- 头像区域 -->
        <!-- from表单区域 -->
        <div class="second-addhui">
          <div @click="sort()" class="first" :class="statu?'addcolor':''">密码登录</div>
          <div @click="sortd()" class="second" :class="!statu?'addcolor':''">验证码登录</div>
        </div>
        <el-form
          label-width="0px"
          class="login_form"
          :model="loginForm"
          :rules="loginFormRules"
          ref="loginFormRef"
        >
          <!-- 用户名框 -->
          <el-form-item prop="loginName">
            <div class="a_loginName">
              <div class="a_img">
                <img src="../assets/image/touxiang.png" alt />
              </div>
              <el-input placeholder="请输入账号" v-model="loginForm.loginName"></el-input>
            </div>
          </el-form-item>
          <!-- 密码框 -->
          <el-form-item prop="password">
            <div class="b_loginName">
              <div class="b_img">
                <img src="../assets/image/password.png" alt />
              </div>
              <el-input
                placeholder="请输入密码"
                v-model="loginForm.password"
                @keyup.enter.native="login()"
                type="password"
              ></el-input>
            </div>
          </el-form-item>
          <!-- 滑动模块的开始 -->
          <div class="jc-component__range" key="byAccount">
            <div class="jc-range" :class="rangeStatus?'success':''">
              <i @mousedown="rangeMove" :class="rangeStatus?successIcon:startIcon"></i>
              {{rangeStatus?successText:startText}}
            </div>
          </div>
          <!-- 滑动模块的结束 -->
          <!-- 按钮 -->
          <!-- <el-form-item>

          </el-form-item>-->
          <el-form-item>
            <!-- <el-button type="text" class="bt">忘记密码</el-button> -->

            <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
          </el-form-item>

          <el-form-item>
            <el-button class="btnsa" type="primary" @click="login" @keyup.enter="login()">登录</el-button>
            <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>
      <!-- s手机号加密码登录 -->

      <!-- 手机号加验证码登录 -->
      <div class="login_box" v-if="!statu">
        <!-- 头像区域 -->
        <!-- from表单区域 -->
        <div class="second-addhui">
          <div @click="sort()" class="first" :class="statu?'addcolor':''">密码登录</div>
          <div @click="sortd()" class="second" :class="!statu?'addcolor':''">验证码登录</div>
        </div>
        <el-form
          label-width="0px"
          class="login_form"
          :model="loginfor"
          :rules="loginFormRulesPhone"
          ref="loginFormRefPhone"
        >
          <!-- 用户名框 -->
          <el-form-item prop="loginName">
            <div class="a_loginName">
              <div class="a_img">
                <img src="../assets/image/shouji.png" alt />
              </div>
              <el-input placeholder="请输入手机号" v-model="loginfor.loginName"></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item  prop="smsCode">
            <div class="yanzhengma">
              <el-input></el-input>


            </div>
          </el-form-item>-->
          <!-- 密码框 -->
          <el-form-item prop="password">
            <div class="b_loginName">
              <div class="b_imgOne">
                <img src="../assets/image/password.png" alt />
              </div>
              <el-input
                class="c_password"
                placeholder="请输入验证码"
                v-model="loginfor.smsCode"
                type="password"
              ></el-input>
              <el-button
                class="d_password"
                type="primary"
                v-show="show"
                :disabled="disabled"
                @click="smsCodeclick"
              >验证码</el-button>
              <el-button type="primary" v-show="!show">{{count}}</el-button>
            </div>
          </el-form-item>
          <!-- 滑动模块的开始 -->
          <div class="jc-component__range" key="byCode">
            <div class="jc-range" :class="rangeStatus?'success':''">
              <i @mousedown="rangeMove" :class="rangeStatus?successIcon:startIcon"></i>
              {{rangeStatus?successText:startText}}
            </div>
          </div>
          <!-- 滑动模块的结束 -->
          <!-- 按钮 -->
          <!-- <el-form-item>

          </el-form-item>-->
          <el-form-item>
            <!-- <el-button type="text" class="bt">忘记密码</el-button> -->

            <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
          </el-form-item>

          <el-form-item>
            <el-button class="btnsa" type="primary" @click="logind" @keyup.enter="logind()">登录</el-button>
            <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>
      <!-- 手机号加验证码登录 -->
    </div>
  </div>
</template>

<script>
import Qs from "qs";
export default {
  // 滑动模块的开始
  props: {
    // 成功之后的函数
    successFun: {
      type: Function,
    },
    //成功图标
    successIcon: {
      type: String,
      default: "el-icon-success",
    },
    //成功文字
    successText: {
      type: String,
      default: "验证成功",
    },
    //开始的图标
    startIcon: {
      type: String,
      default: "el-icon-d-arrow-right",
    },
    //开始的文字
    startText: {
      type: String,
      default: "请向右滑动验证",
      color: "#CCCCCC",
    },
    //失败之后的函数
    errorFun: {
      type: Function,
    },
    //或者用值来进行监听
    status: {
      type: String,
    },
  },
  // 滑动模块的结束
  data() {
    return {
      disabled: true,
      show: true,
      count: "",
      statu: true,
      // 滑动模块的开始
      disX: 0,

      slide: false,
      rangeStatus: false,
      // 滑动模块的结束
      loginForm: {
        loginName: "",
        password: "",
      },
      loginfor: {
        loginName: "",
        smsCode: "",
      },
      loginFormRulesPhone: {
        loginName: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "手机号长度在11个字符",
            trigger: "blur",
          },
        ],
        smsCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "长度在4个字符", trigger: "blur" },
        ],
      },
      // 这是表单的规则验证对象
      loginFormRules: {
        // 验证用户名是否合法
        loginName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        // password: [
        //   { required: true, message: "请输入登录密码", trigger: "blur" },
        //   // {
        //   //   min: 6,
        //   //   max: 15,
        //   //   message: "长度在 6 到 15 个字符",
        //   //   trigger: "blur",
        //   // },
        // ],
      },
    };
  },
  methods: {
    // 点击回车键登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        if (this.statu == true) {
          this.login(); // 定义的登录方法
        } else this.logind(); // 定义的登录方法
      }
    },

    phoneblur() {
      console.log(this.loginfor.loginName);
      if (this.loginfor.loginName.length !== 11) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    // 点击验证发起请求
    async smsCodeclick() {
      if (this.disabled) return this.$message.error("请检查手机号是否正确");
      const { data: res } = await this.$http.post(
        `sendSms?phone=${this.loginfor.loginName}`
      );
      if (res.code !== 200) return this.$message.error("发送验证码失败");
      this.$message.success("发送成功");
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 点击来回切换
    sort() {
      this.statu = true;
      this.disX = 0;
      this.slide = false;
      this.rangeStatus = false;
      this.slide == false;
      this.loginfor = {
        loginName: "",
        smsCode: "",
      };
    },
    sortd() {
      this.statu = false;
      this.disX = 0;
      this.slide = false;
      this.rangeStatus = false;
      this.slide == false;
      this.loginForm = {
        loginName: "",
        password: "",
      };
    },
    // 滑动模块的开始
    //滑块移动
    rangeMove(e) {
      let ele = e.target;
      let startX = e.clientX;
      let eleWidth = ele.offsetWidth;
      let parentWidth = ele.parentElement.offsetWidth;
      let MaxX = parentWidth - eleWidth;
      if (this.rangeStatus) {
        //不运行
        return false;
      }
      document.onmousemove = (e) => {
        let endX = e.clientX;
        this.disX = endX - startX;
        if (this.disX <= 0) {
          this.disX = 0;
        }
        if (this.disX >= MaxX - eleWidth) {
          //减去滑块的宽度,体验效果更好
          this.disX = MaxX;
        }
        ele.style.transition = ".1s all";
        ele.style.transform = "translateX(" + this.disX + "px)";
        e.preventDefault();
      };
      document.onmouseup = () => {
        if (this.disX !== MaxX) {
          ele.style.transition = ".5s all";
          ele.style.transform = "translateX(0)";
          //执行成功的函数
          this.errorFun && this.errorFun();
        } else {
          this.rangeStatus = true;
          if (this.status) {
            this.$parent[this.status] = true;
          }
          console.log(3333), (this.slide = true);
          //执行成功的函数
          this.successFun && this.successFun();
        }
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },

    // 滑动模块的结束

    // 点击重置按钮重置登录表单 resetFields()为element提供的重置表单的方法
    // validate() 为element提供的验证表单的方法
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    logind() {
      if (this.slide == false) {
        this.$message.error("请先拖动滑块");
        return;
      }
      this.$refs.loginFormRefPhone.validate(async (valid) => {
        // console.log(222);
        // console.log(valid);
        if (valid) {
          const { data: res } = await this.$http({
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "post",
            url: "loginByTel",
            data: Qs.stringify(this.loginfor),
          });
          if (res.code == 104) return this.$message.error("没有权限");
          if (res.code == 100) return this.$message.error("此用户不存在");
          if (res.code == 101) return this.$message.error("账号密码不匹配");
          if (res.code == 105) return this.$message.error("token认证失败");
          if (res.code == 106) return this.$message.error("手机号错误");
          if (res.code === 200) {
            this.$message.success("登录成功");
            // 将登陆成功的token保存到本地
            // token只有在当前网站打开期间生效 项目中只能从登陆进入，
            window.sessionStorage.setItem("token", res.data);
            // 通过编程式导航跳转到后台主页
            this.$router.push("/home");
          }
        }
      });
    },
    login() {
      if (this.slide == false) {
        console.log("111dd");
        this.$message.error("请先拖动滑块");
        return;
      }

      this.$refs.loginFormRef.validate(async (valid) => {
        console.log(222);
        if (valid) {
          const { data: res } = await this.$http({
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "post",
            url: "login",
            data: Qs.stringify(this.loginForm),
          });

          if (res.code !== 200) return this.$message.error("账号密码不匹配");
          this.$message.success("登录成功");
          // 将登陆成功的token保存到本地
          // token只有在当前网站打开期间生效 项目中只能从登陆进入，
          window.sessionStorage.setItem("token", res.data);
          // 通过编程式导航跳转到后台主页
          this.$router.push("/home");
        }
      });
    },
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  watch: {
    "loginfor.loginName": {
      handler(newName, oldName) {
        if (newName.length == 11) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

 <style lang="less" scoped>
.el-form-item {
  margin-bottom: 20px;
}
.second-addhui {
  display: flex;

  .first {
    width: 190px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(191, 223, 247, 0.5);
    opacity: 1;
    border-radius: 10px 0px 0px 0px;

    font-size: 18px;
    font-family: PingFang SC;
    // font-weight: bold;
    line-height: 60px;
    color: #0083e3;
    opacity: 1;
    text-align: center;
  }
  .second {
    width: 190px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(191, 223, 247, 0.5);
    opacity: 1;
    border-radius: 0px 10px 0px 0px;

    font-size: 18px;
    font-family: PingFang SC;

    line-height: 60px;
    color: #0083e3;
    opacity: 1;
    text-align: center;
  }
  .addcolor {
    background: #1883e3;
    color: #fff;
  }
}
.login_container { 
  background-color: #2b4b6b;
  background: url(../assets/beijing.png) no-repeat center center;
  background-size: 100% 100%;
  height: 100%;

  .wrapper {
    position: absolute;
    top: 50%;
    // left: 50%;
    right: 0;
    height: 420px;
    box-sizing: border-box;
    margin-right: 100px;
    transform: translate(0, -30%);
    .logo {
      margin-bottom: 10px;
    }
  }
}
.logo {
  display: flex;
  align-items: center;
  // margin-top: 100px;
  // margin-right: 50px;
  .shoujia {
    width: 68px;
    height: 68px;
  }
  .text {
    width: 312px;
    height: 37px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 37px;
    color: #ffffff;
    opacity: 1;
    box-sizing: border-box;
    padding-left: 20px;
  }
}

.login_box {
  // position: absolute;
  width: 380px;
  height: 341px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
}
.login_form {
  box-sizing: border-box;
  // position: absolute;
  // top: 50px;
  // left: 0;
  width: 100%;
  padding: 0 50px;
  padding-top: 30px;
  .a_loginName {
    display: flex;
    align-items: center;
    justify-content: center;
    .a_img {
      width: 38px;
      height: 38px;
      background: #0084e3;
      opacity: 1;
      border-radius: 4px 0px 0px 4px;
      line-height: 38px;
      text-align: center;
      img {
        width: 18px;
        height: 18px;
        border-radius: 4px 0px 0px 4px;
        opacity: 1;
        vertical-align: middle;
      }
    }
  }
  .b_loginName {
    display: flex;
    align-items: center;
    justify-content: center;
    .c_password {
      width: 168px;
      // height: 38px;
    }
    .d_password {
      width: 69px;
      // height: 38px;
      background: #0083e3;
      opacity: 1;
      border-radius: 4px;
      margin-left: 5px;
      text-align: center;
      padding-left: 13px;
    }
    .b_img {
      width: 38px;
      height: 38px;
      background: #0084e3;
      opacity: 1;
      text-align: center;
      border-radius: 4px 0px 0px 4px;
      img {
        width: 18px;
        height: 18px;
        vertical-align: middle;
        border-radius: 4px 0px 0px 4px;
        opacity: 1;
      }
    }
    .b_imgOne  {
      width: 33px;
      height: 38px;
      background: #0084e3;
      opacity: 1;
      // text-align: center;
      border-radius: 4px 0px 0px 4px;
      margin-left: -5px;
      img {
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-left: 8px;
        border-radius: 4px 0px 0px 4px;
        opacity: 1;
      }
    }
  }
}
.jc-component__range .jc-range[data-v-ef68022e] {
  color: #cccc;
}
.btns {
  width: 280px;
  height: 48px;

  border-radius: 4px;
  margin: 0 auto;
}
.btnsa {
  width: 280px;
  height: 48px;
  border-radius: 4px;

  margin: 0 auto;
}
.bt {
  width: 48px;
  height: 17px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: #0083e3;
  opacity: 1;
  padding-right: 50px;
  padding-left: 232px;
}
// 滑动模块的开始
// @mixin jc-flex {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.jc-component__range {
  border-radius: 4px;
  overflow: hidden;
  .jc-range {
    background-color: #ebebeb;
    position: relative;
    transition: 1s all;
    user-select: none;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px; /*no*/
    &.success {
      background-color: #7ac23c;
      color: #fff;
      i {
        color: #7ac23c;
      }
    }
    i {
      position: absolute;
      left: 0;
      width: 50px; /*no*/
      height: 100%;
      color: #919191;
      background-color: #fff;
      border: 1px solid #bbb;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
// 滑动模块的结束
</style>
